//
// Card
//
// .modalReportBody{
//   max-height: 1024px;
//   overflow-y: scroll;
// }
.disabledStop {
  color: #3699FF;
  background-color: #E1F0FF;
  border-color: transparent;
  pointer-events: none;
  opacity: 0.7;
}
.text-info-warehouse {
  color: #8950fc !important;
}
.reporting-select__menu {
  z-index: 9999;
}
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
  opacity: 0.3;
}
.countDiv {
  padding: 0px 5px;
  font-size: 12px;
}
.f16 {
  font-size: 16px !important;
}
#selectUom {
  width: 171px;
}

.tableHeightHidden {
  height: 200px;
}

#selectUomDigit {
  width: 100px;
}
.borderTrBilling {
  border-top: 2px solid #ecf0f3;
  border-radius: 0.42rem;
  border-left: 2px solid #ecf0f3;
  border-right: 2px solid #ecf0f3;
}
.borderTrBillingLast {
  border-bottom: 2px solid #ecf0f3;
  border-radius: 0.42rem;
  border-left: 2px solid #ecf0f3;
  border-right: 2px solid #ecf0f3;
}
.D-margin .row.mt-4 {
  margin-top: 0 !important;
}
.arcWidth {
  width: 80px !important;
}
#modalReports {
  max-width: 1934px;
  transform: translate(0, -30px) !important;
}
.paddingBottomStatus {
  padding-bottom: 4px !important;
}
#modalVariance {
  max-width: 1934px;
  transform: translate(0, -30px) !important;
}
.pbStatusPayable {
  padding-bottom: 7px !important;
}
.borderStatusCount {
  border: 1px solid gray;
}
.headerTitleCardStatus {
  font-size: 15px;
  margin-top: -9px;
  border-radius: 0;
  background: #fff !important;
}
.sticky-po {
  position: sticky;
  top: 10rem;
}
.alert-row {
  background-color: "#fdf2e9";
}
.SO-Date span .col-lg-6:nth-child(2) {
  margin-top: 1rem !important;
}
.spacer-none .MuiTablePagination-spacer {
  display: none;
}
.paddingUpload {
  padding: 70px !important;
}
.heightTypeAttachments {
  height: 286px;
  overflow-y: scroll;
}
.position-unset {
  position: unset;
}
.topFixedMenu {
  width: 190px !important;
}
.header-detail .card-header {
  padding-left: 0;
  align-items: end;
}
.position-unset {
  position: unset;
}
.topFixedMenu {
  width: 190px !important;
}
.loaderStatuses {
  text-align: -webkit-center;
  margin-bottom: 3rem;
}
.scrollCustomerCheckbox {
  height: 268px;
  overflow-y: scroll;
}
.slick-prev:before,
.slick-next:before {
  color: #000000;
}
.slick-slider.slick-initialized {
  top: 80px;
  z-index: 1;
}
.modeHeight {
  height: 268px;
}
.widthBasic {
  width: 167px;
}
.heightCollapsed {
  height: 41px !important;
}
.headerTitleExpand .card-label {
  font-size: 14px !important;
}
.collapsedClass {
  z-index: 1;
}

.noDataCheckList {
  text-align: -webkit-center;
  height: auto;
  margin-bottom: 1rem;
}
.op-6 {
  opacity: 0.6;
}
.bg-primary.curve-bg.container-fluid {
  height: 120px;
  display: block;
  box-sizing: border-box;
  background-color: #5050ee;
  clip-path: ellipse(66% 100% at 50.16% 100%);
}
.disabledAttachmentElement {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.custom-table td {
  border: 0 !important;
  border-bottom: 2px solid #3699ff !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}
.heightUserGroups {
  height: 450px;
  overflow-y: scroll;
}
.custom-table thead {
  display: none;
}
.checkbox-management label span input {
  z-index: 0;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .dropdown-menu.dropdown-menu-xxl {
    width: 1100px !important;
  }
}
#kt_quick_search_toggle input::placeholder {
  font-weight: 700;
}
.btnCloseHandle {
  background-color: black;
  color: white;
  position: absolute;
  border-radius: 50%;
  top: -43px;
  right: -29px;
}
div#UnitsId .css-yk16xz-control {
  border-radius: 0px;
}
.bgBolColumn {
  background-color: #113059;
  border: 1px solid #113059 !important;
  border-bottom: 1px solid white !important;
  border-top: 1px solid white !important;
  color: white !important;
}
.bbItem {
  border-bottom: 1px solid white !important;
}
.stopsTable tr th:last-child {
  white-space: nowrap !important;
}
.stopsTable tr td:last-child {
  white-space: nowrap !important;
}

.requiredStartField {
  font-size: 8px;
  bottom: 4px;
  position: relative;
}
.lockOpenColor {
  color: red;
}
.unlockOpenColor {
  color: #61a44b;
}
.menu-wrapper--inner {
  background-color: #f3f6f9;
}
.bgDiscount {
  background-color: #d48b8b;
}
.horizontal-menu {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #80808f;
}
.react-datetime-picker__wrapper {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-color: lightGray !important;
}
.react-datetime-picker {
  width: 100%;
}
td.Location-break {
  white-space: nowrap !important;
}
.disabledSelect {
  opacity: 0.7;
  pointer-events: none;
}
.modal-90w {
  width: 90%;
  max-width: none !important;
}
.copyLink:hover {
  color: #8950fc;
}
.receiptMgmtTable tr td:nth-child(3) {
  white-space: nowrap !important;
}
.copyLink:hover {
  color: #8950fc;
}
.leftAlignLocation {
  text-align: left !important;
}
.labelQCFailed {
  background-color: #f64e60 !important;
  color: white;
}
.labelQCFailed:hover {
  color: white;
}
.opacityClickEdit {
  opacity: 0.6;
}
.container-detail-card {
  position: absolute;
  top: 0;
  background-color: white;
  padding: 2rem;
}
.spanLocation {
  font-weight: 700;
  font-size: 16px;
}
.SkuElement .css-2b097c-container {
  z-index: 2222;
}
.pickUpLocationElement .css-2b097c-container {
  z-index: 100;
}
.rowSticky {
  position: sticky;
  right: 0;
  z-index: 1;
  background: white;
  box-shadow: -3px -1px 5px #eeeeeebb;
}
.timeline.timeline-5 .timeline-items .timeline-item .timeline-desc {
  padding: 9px 0 0 44px;
}
.tableOrdersConfirmVV th:nth-child(2) {
  width: 10%;
}
/* DropDown Counter Filter */
.react-bootstrap-table-pagination .dropdown .dropdown-menu {
  height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.timeline.timeline-5 .timeline-items .timeline-item .timeline-media {
  position: absolute;
  top: 0;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  line-height: 0;
}
.double-circle {
  background: rgba(0, 116, 217, 0.8);
  margin: 50px;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 3px white;
  border: 2px solid rrgba(0, 116, 217, 0.8);
}
.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}
.icon-delete.MuiFab-secondary {
  background-color: rgb(220, 0, 78);
}
.icon-delete.MuiFab-secondary:hover {
  background-color: rgb(154, 0, 54);
}
.symbol.symbol-light-Rtv .symbol-label {
  background-color: #fe8a71;
  color: #fff;
}
.symbol.symbol-light-Appointed .symbol-label {
  background-color: #869890;
  color: #fff;
}
.symbol.symbol-light-Received .symbol-label {
  background-color: #4ca1af;
  color: #fff;
}
.symbol.symbol-light-QC .symbol-label {
  background-color: #b3c43c;
  color: #fff;
}
.symbol.symbol-Shipped .symbol-label {
  background-color: #a2738c;
  color: #fff;
}
.symbol.symbol-Exceptions .symbol-label {
  background-color: #ffe2e5;
  color: #f64e60;
}
.symbol.symbol-Closed .symbol-label {
  background-color: #95371d;
  color: #fff;
}
.symbol.symbol-Loaded .symbol-label {
  background-color: #8785a2;
  color: #fff;
}
.symbol.symbol-Palletized .symbol-label {
  background-color: #3c78ae;
  color: #fff;
}
.symbol.symbol-Processed .symbol-label {
  background-color: #5a3662;
  color: #fff;
}
.vessel-details .table td {
  white-space: normal;
}
.shadow-none {
  box-shadow: none !important;
}
.border-timeline:before {
  content: "";
  position: absolute;
  width: 91%;
  height: 1.9px;
  top: 10.5px;
  bottom: 0;
  background-color: #c3e1ff;
  transform: rotate(0deg);
}
div#Contact {
  background-color: #1a1a27;
}
.container-fluid.bg-gray {
  background-color: #f7f8f9;
}
div#kt_login .Landing {
  left: 0;
  height: 100px;
  align-items: center;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
  background: #006edc;
}
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #fff;
}
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #ffffff;
}
button.react-daterange-picker__clear-button.react-daterange-picker__button {
  order: 1;
}
.react-calendar__month-view__days__day--weekend {
  color: #f64e60;
}
.btnCloseBlack {
  background-color: black;
  color: white;
  position: absolute;
  border-radius: 50%;
  top: -33px;
  right: -20px;
}
.react-daterange-picker__inputGroup {
  padding: 2px 8px;
  height: 30px;
}
.tableCost thead {
  display: table-header-group !important;
}
.table.tableCost:not(.table-bordered) thead th,
.table.tableCost:not(.table-bordered) thead td {
  border-top: 0 !important;
  border: 0;
  border-bottom: 2px solid #3699ff !important;
}
#modalOneMoveMasterLtl {
  max-width: 1300px !important;
}
#modalPoItems {
  max-width: max-content !important;
  transform: translate(0, -30px) !important;
}
.borderInput1 {
  border: 2px solid #3699ff;
  font-weight: 700;
}
.borderInput2 {
  border: 2px solid rgb(71, 184, 34);
  font-weight: 700;
}
.borderInput3 {
  border: 2px solid #ffa800;
  font-weight: 700;
}
.borderInput4 {
  border: 2px solid #b61ea2;
  font-weight: 700;
}
.borderInput5 {
  border: 2px solid #ca2121;
  font-weight: 700;
}
svg.react-daterange-picker__calendar-button__icon.react-daterange-picker__button__icon {
  width: 14px;
  height: 14px;
}
svg.react-daterange-picker__clear-button__icon.react-daterange-picker__button__icon {
  height: 14px;
  width: 14px;
}
.react-daterange-picker__wrapper {
  border: 1px solid #e5eaee;
  border-radius: 0.42rem !important;
}
.react-calendar__tile--now {
  background: #e1f0ff;
}
.Dashboard-Transportation .col-md-10.mx-auto .MuiButtonBase-root {
  align-items: baseline;
}
.Dashboard-Transportation .MuiTabs-indicator {
  display: none;
}
.svg-icon.svg-icon-6x.svg-icon-primary.d-block.my-2.loaded {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.word-break .col-lg-8.text-primary {
  word-break: break-all;
}
.Dashboard-Transportation .table-responsive.scroll-dash {
  overflow: auto;
  max-height: 415px;
}
.Dashboard-Transportation .table-responsive.scroll-dash th {
  position: sticky;
  top: -1px;
  background: white;
  z-index: 1;
}
span.MuiButton-label.Dashboard {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}
// Base'
.react-bootstrap-table.table-responsive.reporting .table th,
.react-bootstrap-table.table-responsive.reporting .table td {
  white-space: normal !important;
}
.react-bootstrap-table.table-responsive.reporting .table th,
.table td:last-child {
  white-space: nowrap !important;
}
.row.react-bootstrap-table-pagination {
  margin-top: 1.5rem;
}
td.expand-cell {
  font-weight: 700;
  color: #52a7fe;
}
.now-rapTable .table td {
  white-space: normal !important;
}
.now-rapTable .table td:nth-child(2) {
  white-space: nowrap !important;
}
table.MuiTable-root.LineItems th {
  white-space: nowrap;
}
table.MuiTable-root.LineItems td {
  white-space: normal;
}
th.sortable {
  white-space: nowrap;
}
.offcanvas.offcanvas-right.pr-10.py-10.pl-5.offcanvas-on {
  width: 450px;
  right: 0;
}
.offcanvas.offcanvas-right.pr-10.py-10.pl-5 {
  right: -465px;
  left: auto;
}
span#EmailUserNameProfile {
  overflow-wrap: anywhere;
}
.fade.cancel-Exceptions.modal.show {
  z-index: 9999;
}
td.row.mb-0.border-0.place-content-center {
  padding-left: 1.7rem;
}
span.MuiRating-root label {
  font-size: 1.5rem;
  font-weight: 400;
  color: #ffb400;
}
.aside .aside-menu.ps > .ps__rail-y > .ps__thumb-y:hover,
.aside .aside-menu.ps > .ps__rail-y > .ps__thumb-y:focus {
  width: 15px;
}
.input-group-append .btn,
.input-group-prepend .btn {
  z-index: 0;
}
body#kt_body {
  overflow-x: hidden !important;
}
.accordion .card.card-custom.gutter-b.tracking-list {
  overflow: hidden !important;
}
.col-lg-4.trucking-scroll .card.card-custom {
  overflow-y: scroll !important;
}
.bl-form table th {
  border: 1px solid #113059;
}
.symbol.symbol-light-Enroute .symbol-label {
  background-color: #eecda34a;
  color: #292623;
}
.symbol.symbol-light-Receiv .symbol-label {
  background-color: #4ca1afad;
  color: #2c3e50;
}
#layout-portal
  .dialog.dialog-default.dialog-loader.dialog-top-center.dialog-hidden {
  display: none;
}
#layout-portal .dialog.dialog-default.dialog-loader.dialog-top-center {
  background-color: #ffffffb0;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 0;
}
#LodingGlobalId img {
  width: 60%;
}

form#FilterShipmentManagement .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}
form#FilterShipmentManagement .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .CMS {
    display: block !important;
  }
  .h1-md {
    font-size: 2rem !important;
  }
  .select__menu {
    z-index: 999;
  }
}
@media screen and (max-width: 63.9375em) {
  .Dashboard-Transportation .MuiAppBar-root {
    display: block;
  }
  .Dashboard-Transportation .MuiTabs-flexContainer {
    display: block;
  }
  a.home-page {
    align-self: center;
  }
}
.performance-switch {
  position: absolute;
  z-index: 1;
  top: 54px;
  text-align-last: center;
}
.react-daterange-picker__wrapper {
  border-radius: 0.2rem;
  padding: 0.2rem;
}
.react-daterange-picker__calendar.react-daterange-picker__calendar--open {
  z-index: 26;
}
table.table.table.table-head-custom.table-vertical-center.table-responsive-sm {
  font-size: 12px;
  white-space: nowrap;
}

.passwordEye {
  cursor: pointer;
}
.passwordEye1 {
  cursor: pointer;
}
.userKeyGenerate {
  width: 8% !important;
  height: 42% !important;
  margin-top: -0.6rem;
}
.block-chain span.MuiTab-wrapper {
  font-size: 14px;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #008ffb !important;
}
.date-picker-input {
  height: 40px !important;
}
.marginInlineStartLabel {
  margin-inline-start: 6px;
}
.react-google-flight-datepicker {
  margin-top: 0.5rem;
}
.rmDateRange .react-google-flight-datepicker {
  margin-top: -0.5rem;
}
.smDateRange .react-google-flight-datepicker {
  margin-top: -1rem;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.align-items-center.bg-white.MuiPaper-elevation4
  .MuiTab-textColorPrimary.Mui-selected {
  font-size: 16px;
  font-weight: 600;
  color: #008ffb;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.align-items-center.bg-white.MuiPaper-elevation4
  .PrivateTabIndicator-colorPrimary-68 {
  background-color: #3699ff;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.align-items-center.bg-white.MuiPaper-elevation4
  span.MuiTab-wrapper {
  font-size: 16px;
  font-weight: 600;
}
input#combo-box-demo {
  width: auto;
}
div#kt_transportation_widget_7_chart
  .apexcharts-legend.apexcharts-align-center.position-right {
  display: none;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.align-items-center.bg-transparent.MuiPaper-elevation4
  .PrivateTabIndicator-colorPrimary-68 {
  background-color: #3699ff;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.align-items-center.bg-transparent.MuiPaper-elevation4
  .MuiTab-textColorPrimary.Mui-selected {
  color: #3699ff;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.align-items-center.bg-transparent.MuiPaper-elevation4
  .PrivateTabIndicator-colorPrimary-172 {
  background-color: #3699ff;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.align-items-center.bg-transparent.MuiPaper-elevation4
  svg.MuiSvgIcon-root {
  width: 4em;
  height: 2em;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.align-items-center.bg-transparent.MuiPaper-elevation4 {
  z-index: 1;
  box-shadow: none;
}
.active-over {
  overflow: auto;
}
#__range-picker-container .full-date-picker-container {
  background-color: #fff;
  margin-top: 1.5rem;
}
.default-placeholder .text .dates-container {
  font-size: 1rem;
  color: black;
  font-weight: 700;
}
.MuiDialog-root.Add-Log
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  overflow-y: visible;
}
.MuiDialog-root.Add-Log .MuiDialogContent-root {
  overflow-y: visible;
}
.itemtoship .form-control {
  width: auto;
}
.second-bg th {
  background-color: #2f5496;
}
.second-bg2 td:first-child {
  background-color: #2f5496;
  color: white;
  font-weight: 500;
  width: 27%;
}
.second-bg3 td:first-child {
  background-color: #2f5496;
  color: white;
  font-weight: 500;
}
.CenterColDiv{
  text-align-last: center;
}
.w50Img{
  width: 50%;
}
@page {
  size: auto;
  font-size: small;
  margin: 20mm 15mm 20mm 15mm;
}
.table-nowrap table.footer-bol td.space-bol {
  white-space: pre-line;
  font-size: 12px;
}
.layout-view-pdf {
  display: none;
}
.d-hide {
  display: none;
}
.breakPageBol{
  break-after: page !important;
}
.article { 
  border-bottom: 0.5px solid lightgray;
  margin-bottom: 2rem;
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    font-size: 14px;
  }
  .article { 
    page-break-after: always;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .itemPick {
    margin-bottom: 1.5rem;
  }
  .itemDrop {
    margin-bottom: 1.5rem;
  }
  .layout-view {
    display: none;
  }
  .layout-view-pdf {
    display: block;
  }
}
.poNotHOt {
  margin-left: 1.5rem !important;
}
.widthGrab {
  width: 200px;
}
.f-13 {
  font-size: 13px;
}
.widthTableOut {
  width: 30% !important;
}
.marginPrint {
  padding-bottom: 7.5rem !important;
}
@media print {
  .marginPrint {
    padding-bottom: 7.5rem !important;
  }
  .widthTableOut {
    width: auto !important;
  }
  .widthGrab {
    width: 200px;
  }
  .poNotHOt {
    margin-left: 1.5rem !important;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .pagebreak {
    page-break-before: always;
    height: auto;
  }
  .article { 
    page-break-after: always;
  }
}
@page {
  margin: 20mm 15mm 20mm 15mm;
  width: 100%;
  font-size: small;
}
@page {
  margin: 20mm 15mm 20mm 15mm;
  width: 100%;
  font-size: small;
}
@media print {
  .article { 
    page-break-after: always;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1.5px solid #ecf0f3;
  white-space: nowrap;
}
.table:not(.table-bordered) thead th,
.table:not(.table-bordered) thead td {
  border-top: 1px solid #ecf0f3 !important;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  font-weight: bolder !important;
  color: #454546 !important;
}
.ShipmentManagment .MuiMenuItem-root {
  white-space: normal !important;
}
.smooth-dnd-container.horizontal {
  white-space: normal !important;
  display: flex;
}
.edit-shipment i {
  padding: 0.15rem;
}
.edit-shipment .MuiPaper-elevation1 {
  box-shadow: 0 0 black !important;
}
.ProductShipment-Details .MuiPaper-elevation1 {
  box-shadow: 0 0 black !important;
}
.ProductShipment-Details i {
  padding: 0.15rem;
}
.search-bg {
  background-color: #663259 !important;
  background-position: right bottom;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-image: url(/media/svg/patterns/taieri.svg);
}
.bl-form a.text-dark-75.text-hover-primary.font-weight-bold.font-size-lg.mb-1 {
  font-size: smaller;
}
table.footer-bol td.space-bol {
  white-space: nowrap;
  font-size: 12px;
}
table.footer-bol th.space-bol {
  white-space: nowrap;
}
.bl-form table td {
  border: 2px solid #11305980;
  color: #113059;
  font-size: normal;
  padding: 0 0.5rem;
}

.bl-form table tbody {
  border: 2px solid #11305980;
}
.bl-form thead,
.bl-form tfoot {
  background-color: #113059;
  color: #fff;
  font-size: normal;
  border: 2px solid #11305980;
}
.bl-form td.w-head {
  background-color: #113059;
  color: #fff;
  border: 2px solid #113059;
}
.bl-form input {
  border: 0;
  background-color: #e0ffff73;
}
.bl-form textarea {
  border: 0;
  background-color: #e0ffff73;
}
.bl-form input:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.wave .zoomout:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
}
.react-slideshow-container + div.indicators {
  display: none;
}
h1.text-white.font-weight-bolder.mb-13.display-1 {
  text-shadow: 2px 2px 4px #000000;
}
.bg-home {
  background-size: cover;
  background-position: center;
}
a.canvasjs-chart-credit {
  display: none;
}
.h-100.card.card-custom.undefined {
  height: 110vh !important;
  overflow: auto;
}
.MuiPaper-elevation1 {
  box-shadow: 0 0 5px 0 #523f6926 !important;
}
.card.card-custom.gutter-b.bg-transparent.lsp-carrier
  a.text-dark-75.text-hover-primary.font-weight-bold.font-size-lg.mb-1 {
  font-size: 13px;
}
//CKEDITOR
.btnImageUrl {
  position: relative;
  top: 37px;
  z-index: 11;
  left: 573px;
}
.bodyDetail figure {
  display: block !important;
}
.bg-transparent.lsp-carrier {
  box-shadow: 0px 0px 30px 0px #503f6400 !important;
  border: 0;
}
.col-12.MT3.px-0.bg-transparent .tab-content {
  background-color: transparent;
}
.d-flex.flex-column.font-weight-bold.text-right span {
  position: absolute;
  left: 166px;
}
.d-flex.flex-column.font-weight-bold.text-right {
  justify-content: center;
}
.timeline.timeline-5:before {
  left: auto !important;
  margin-left: 1.5rem;
}
.smooth-dnd-container {
  min-height: 229px;
}
.card.card-custom {
  box-shadow: $card-box-shadow;
  border: 0;

  // Header
  > .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    //height: $card-header-height;
    min-height: $card-header-height;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;

    // Title
    .card-title {
      display: flex;
      align-items: center;
      margin: $card-header-spacer-y;
      margin-left: 0;
      flex-wrap: wrap;

      .card-icon {
        margin-right: 0.75rem;
        line-height: 0;

        i {
          font-size: 1.25rem;
          color: $dark-50;
          line-height: 0;

          &:after,
          &:before {
            line-height: 0;
          }
        }

        .svg-icon {
          @include svg-icon-size(24px);
          @include svg-icon-color($dark-50);
        }
      }

      &,
      .card-label {
        font-weight: 500;
        font-size: 1.275rem;
        color: $dark;
      }

      .card-label {
        margin: 0 0.75rem 0 0;
        flex-wrap: wrap;
      }

      // Description
      small {
        color: $text-muted;
        font-size: 1rem;
      }
    }

    // Toolbar
    .card-toolbar {
      display: flex;
      align-items: center;
      margin: $card-header-spacer-y 0;
      flex-wrap: wrap;
    }

    // Line tabs integration
    &.card-header-tabs-line {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      align-items: stretch;

      .card-toolbar {
        margin: 0;
      }

      .nav {
        border-bottom-color: transparent;

        .nav-item {
          align-items: stretch;
        }

        .nav-link {
          padding-top: $card-spacer-y;
          padding-bottom: $card-spacer-y;
        }
      }
    }

    &.card-header-right {
      justify-content: flex-end;
    }
  }

  // Body
  > .card-body {
    padding: $card-spacer-y $card-spacer-x;
  }

  // Footer
  > .card-footer {
    background-color: transparent;
  }

  // Make full height
  &.card-stretch {
    display: flex;
    align-items: stretch !important;
    flex-direction: column;
    height: 100%;

    &.gutter-b {
      height: calc(100% - #{$grid-gutter-width});
    }

    &.card-stretch-half {
      height: 50%;

      &.gutter-b {
        height: calc(50% - #{$grid-gutter-width});
      }
    }

    &.card-stretch-third {
      height: 33.33%;

      &.gutter-b {
        height: calc(33.33% - #{$grid-gutter-width});
      }
    }

    &.card-stretch-fourth {
      height: 25%;

      &.gutter-b {
        height: calc(25% - #{$grid-gutter-width});
      }
    }
  }

  // Card header fit style
  &.card-fit {
    > .card-header {
      border-bottom: 0;
    }

    > .card-footer {
      border-top: 0;
    }
  }

  // Card space style
  &.card-space {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;

    > .card-header {
      padding-left: 0;
      padding-right: 0;
    }

    > form > .card-body,
    > .card-body {
      padding-left: 0;
      padding-right: 0;
    }

    > form > .card-footer,
    > .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Sticky card
  &.card-sticky {
    > .card-header {
      transition: left 0.3s, right 0.3s, height 0.3s;
      //height: $card-sticky-header-height;
      min-height: $card-sticky-header-height;
    }
  }

  .card-sticky-on &.card-sticky {
    > .card-header {
      transition: left 0.3s, right 0.3s, height 0.3s;
      position: fixed;
      box-shadow: $card-sticky-shadow;
      z-index: $card-sticky-zindex;
      background: $card-sticky-header-bg;
    }
  }

  // Transparent Background
  &.card-transparent {
    background-color: transparent;
  }

  // No Shadow
  &.card-shadowless {
    box-shadow: none;
  }

  // Border style
  &.card-border {
    box-shadow: none;
    border: 1px solid $card-border-color;
  }

  // Scroll
  .card-scroll {
    position: relative;
    overflow: auto;
  }

  // Collapsed Mode
  &.card-collapsed {
    > form,
    > .card-body {
      display: none;
    }
  }

  .card-header {
    .card-toolbar {
      [data-card-tool="toggle"] {
        i {
          transition: $transition;
        }
      }
    }
  }

  &.card-collapsed,
  &.card-collapse {
    .card-header {
      .card-toolbar {
        [data-card-tool="toggle"] {
          i {
            transition: $transition;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.rssParent div img {
  height: 80px;
  width: 120px;
}
// Utilities
.card-spacer {
  padding: $card-spacer-y $card-spacer-x !important;
}

.card-spacer-x {
  padding-left: $card-spacer-x !important;
  padding-right: $card-spacer-x !important;
}

.card-spacer-y {
  padding-top: $card-spacer-y !important;
  padding-bottom: $card-spacer-y !important;
}

.card-rounded {
  border-radius: $card-border-radius;
}

.card-rounded-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}
.ck-editor__editable_inline {
  min-height: 140px !important;
}
#tableSKu .table th,
.table td {
  font-size: 0.875rem !important;
  white-space: inherit;
}
#tableSKu {
  overflow-x: inherit !important;
}
.date.is-focus {
  z-index: 0 !important;
}
@include media-breakpoint-up(lg) {
  .react-bootstrap-table.table-responsive.heightTableCustom {
    overflow-x: visible;
  }
}
@include media-breakpoint-down(md) {
  .table-responsive td.border-0.Rate input {
    width: 176px;
  }
}
// Mobile mode
@include media-breakpoint-down(sm) {
  button.receive-btn {
    position: relative;
    top: -116px;
  }
  .card.card-custom {
    // Header
    > .card-header:not(.flex-nowrap) {
      min-height: 0;
      padding-top: $card-header-spacer-y;
      padding-bottom: $card-header-spacer-y;
    }
  }
  .table-responsive td.border-0.Rate input {
    width: 176px;
  }
  h1.error-title.font-weight-boldest.text-info.mt-10.mt-md-0.mb-12 {
    font-size: 7rem !important;
  }
  .login.login-1 .login-form {
    width: 100%;
    max-width: 100%;
  }
  a.btn.btn-icon.btn-light.btn-hover-primary.btn-sm.ml-1 {
    left: 10rem !important;
  }
}
h1.error-title.font-weight-boldest.text-info.mt-10.mt-md-0.mb-12 {
  font-size: 14rem !important;
}

.FixedActionsExpanded {
  position: sticky;
  right: 0;
  z-index: 1;
  background: white;
  box-shadow: -3px -1px 5px #eeeeeebb;
}
// Card related helper classes
.rounded-card {
  @include border-radius($card-border-radius);
}

.rounded-card-top {
  @include border-top-left-radius($card-border-radius);
  @include border-top-right-radius($card-border-radius);
}

.rounded-card-bottom {
  @include border-bottom-left-radius($card-border-radius);
  @include border-bottom-right-radius($card-border-radius);
}
.dangerlist {
  font-size: 13px;
  white-space: nowrap;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .table-responsive td.border-0.Rate input {
    width: 176px;
  }
}
// .dropdown-menu.p-0.m-0.dropdown-menu-right.dropdown-menu-anim-up.dropdown-menu-lg.dropdown-menu.show.dropdown-menu-right {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   transform: translate3d(-1471px, 65px, 0px) !important;
//   will-change: transform;
//   width: 1514px !important;
// }
.check-file {
  display: block;
}
div#kt_quick_search_dropdown {
  border: solid;
  border-color: #3698fe;
  border-radius: 5px;
}
.wrapper1 tbody {
  display: none;
}
.wrapper1 thead {
  opacity: 0;
}
.wrapper1 th {
  border: 0 !important;
}

@include media-breakpoint-up(md) {
  [data-scrolltop="on"] .position-tap {
    position: fixed;
    z-index: 2;
    background-color: #fff;
    transform: translate(-17px, -66px);
    width: 100%;
    scroll-behavior: smooth;
  }
  .position-tap {
    width: 100%;
    background-color: white;
    transform: translate(-16px, -64.5px);
    position: fixed;
    padding: 0.1rem 2.2rem 0;
    z-index: 2;
  }
  .subheader-position .pt-10 {
    margin-top: 2rem;
  }
  .overflow-tab {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 90%;
  }
  .btn-fade .btnCollapseLeft {
    transform: translate(-18px, 82px);
    position: absolute;
    display: inline-flex !important;
  }
  .btnCollapseLeft {
    transform: translate(265px, 81px);
    position: absolute;
    z-index: 1;
    display: none !important;
  }
  .btnCollapseLeft.collapsedClass {
    position: absolute;
    transform: translate(-18px, 82px);
  }
}
@include media-breakpoint-up(lg) {
  .subheader-position .pt-10 {
    margin-top: 2rem;
  }
  .overflow-tab {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 90%;
  }
  [data-scrolltop="on"] .position-tap {
    position: fixed;
    z-index: 2;
    background-color: #fff;
    transform: translate(-24px, -50px);
    width: 100%;
    scroll-behavior: smooth;
  }
  .position-tap {
    width: 100%;
    background-color: white;
    transform: translate(-24px, -48.5px);
    position: fixed;
    padding: 0.1rem 2.2rem 0;
    z-index: 2;
  }
  .btn-fade .btnCollapseLeft {
    position: absolute;
    transform: translate(-18px, 82px);
    display: inline-flex !important;
  }
  .btnCollapseLeft {
    position: absolute;
    transform: translate(242px, 77px);
    z-index: 1;
    display: none !important;
  }
  .btnCollapseLeft.collapsedClass {
    position: absolute;
    transform: translate(-18px, 82px);
  }
}
@include media-breakpoint-up(xl) {
  .subheader-position .pt-10 {
    margin-top: 2rem;
  }
  .overflow-tab {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 90%;
  }
  [data-scrolltop="on"] .position-tap {
    position: fixed;
    z-index: 2;
    background-color: #fff;
    transform: translate(-24px, -51px);
    width: 100%;
    scroll-behavior: smooth;
  }
  .position-tap {
    width: 100%;
    background-color: white;
    transform: translate(-24px, -49.5px);
    position: fixed;
    padding: 0.1rem 2.2rem 0;
  }
  .btn-fade .btnCollapseLeft {
    position: absolute;
    transform: translate(-18px, 82px);
    display: inline-flex !important;
  }
  .btnCollapseLeft {
    position: absolute;
    transform: translate(371px, 82px);
    z-index: 1;
    display: none !important;
  }
  .btnCollapseLeft.collapsedClass {
    position: absolute;
    transform: translate(-18px, 82px);
  }
}
@include media-breakpoint-only(xs) {
  .btnCollapseLeft.collapsedClass {
    display: none;
  }
  .btnCollapseLeft {
    display: none;
  }
  .overflow-tab {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 90%;
  }
  .position-tap {
    width: 100%;
    background-color: white;
    transform: translate(-13px, -41.5px);
    position: fixed;
    padding: 0 2rem;
    z-index: 2;
  }
  .btn-fade .btnCollapseLeft {
    transform: translate(-18px, 82px);
    position: absolute;
    z-index: 1;
    display: inline-flex !important;
  }
}
@media only screen and (max-width: 768px) {
  .btn-fade .btnCollapseLeft.collapsedClass {
    display: none;
  }
  
}
